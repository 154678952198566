
import { defineComponent, onMounted } from 'vue'
import { routGo } from '../hooks/links'

export default defineComponent({
  name: 'Unite',
  setup () {
    onMounted(() => {
      _hmt.push(['_trackPageview', '/unite'])
    })
    return { routGo }
  }
})
